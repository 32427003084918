import React from "react"
import { Box, Container, Grid, Heading } from "@theme-ui/components"

const Video = ({ title, embedCode, customHtml, addMarginBottom }) => {
  return (
    <Box sx={{ position: "relative", mb: addMarginBottom ? [0, 0, 7] : 0 }}>
      <Box
        sx={{
          position: ["absolute"],
          width: ["100%", "100%", "100%", "80%"],
          height: "100%",
          top: 0,
          left: 0,
          backgroundColor: "dark",
        }}
      />
      <Container sx={{ paddingY: [6, 6, 6, 7] }}>
        <Grid columns={[1, 1, "2fr 3fr", "2fr 3fr"]} gap={[16, 16, 32, 64]}>
          <Box sx={{ zIndex: 1 }}>
            <Heading
              dir="invalid"
              variant="h2"
              as="h2"
              sx={{ m: 0, width: ["100%", "100%", "100%", "70%"] }}
              color="light"
            >
              {title}
            </Heading>
            {customHtml && (
              <Box
                sx={{
                  position: "relative",
                  mt: 3,
                  p: {
                    fontSize: [2, 3],
                    color: "light",
                    a: {
                      color: "light",
                      textDecoration: "underline",
                    }
                  },
                }}
                dangerouslySetInnerHTML={{ __html: customHtml }}
              />
            )}
          </Box>
          <Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "0px",
                paddingBottom: "56.25%",
                backgroundColor: "darkBackground",
                iframe: {
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                },
              }}
              dangerouslySetInnerHTML={{ __html: embedCode }}
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default Video
